import { color, palette, spacing } from '@/Layouts/theme';
import React, { CSSProperties, useEffect, Fragment, ReactElement } from 'react';
import { CardCaptionProps } from './card-caption.props';
import { CardProduct } from '..';
import { Icon } from 'react-icons-kit';
import { arrows_slim_right } from 'react-icons-kit/linea/arrows_slim_right';
import { useMst } from '@/models/root-store';

const PRODUCT_DESCRIPTION: CSSProperties = {
  paddingTop: spacing[3] + spacing[3],
  marginBottom: 'auto',
};

const BOTTOM_DESCRIPTION: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-end',
};

const ARROW: CSSProperties = {
  color: color.mainColor4,
  // position: 'absolute',
  bottom: 0,
  right: 15,
  zIndex: 2,
};

const TEXT_ELLIPSIS: CSSProperties = {
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  marginBottom: 0,
};

export function CardCaption(props: CardCaptionProps): ReactElement {
  const {
    source,
    containerImageStyle,
    imageStyle,
    containerStyle,
    topic,
    content,
    topicStyle,
    contentStyle,
    disableAos = true,
  } = props;

  const { ouayUnStore } = useMst();

  const topicStyleMain: CSSProperties = {
    lineHeight: ouayUnStore?.screenSize?.width < 768 ? 1.3 : 1.4,
    ...topicStyle,
  };
  const contentStyleMain: CSSProperties = {
    lineHeight: ouayUnStore?.screenSize?.width < 768 ? 1.4 : 1.5,
    ...contentStyle,
  };

  return (
    <Fragment key="card-caption">
      <CardProduct
        source={source}
        containerStyle={containerStyle}
        imageStyle={imageStyle}
        containerImageStyle={containerImageStyle}
        disableAos={disableAos}
      />
      <div style={PRODUCT_DESCRIPTION}>
        <div
          dangerouslySetInnerHTML={{
            __html: topic,
          }}
          style={topicStyleMain}
        />
        <div style={BOTTOM_DESCRIPTION}>
          <div style={contentStyleMain}>
            <p
              dangerouslySetInnerHTML={{ __html: content }}
              style={TEXT_ELLIPSIS}
            />
          </div>
        </div>
      </div>
      <Icon icon={arrows_slim_right} size={30} style={ARROW} />
    </Fragment>
  );
}

export const customerReview = [
  {
    fileName: '01_INTER_NETIONAL.png',
    link: 'https://www.instagram.com/p/CYk8eD4v1HY/',
  },
  {
    fileName: '02_INKHEARTT.png',
    link: 'https://www.instagram.com/p/CYjdtg3lty9/',
  },
  {
    fileName: '03_NUNNAPAT_RATTANAVUTINUN.png',
    link: 'https://www.instagram.com/p/CYgqT4HvUzd/',
  },
  {
    fileName: '04_PINKYPRAE.png',
    link: 'https://www.facebook.com/Hersnan.nan/posts/3066504460284523',
  },
  {
    fileName: '05_THANVARAT_LUENGMANEEWECH.png',
    link: 'https://www.facebook.com/ddjaspire/posts/1847868738716789',
  },
  {
    fileName: '06_TECHIN_THAWORNSUTJARITKUL.png',
    link: 'https://www.facebook.com/tskdream/posts/4801809133216163',
  },
  {
    fileName: '07_PHINGPIT.png',
    link: 'https://www.instagram.com/p/CYY6JMLPcgK/',
  },
  {
    fileName: '08_AANNANNS.png',
    link: 'https://www.instagram.com/p/CYYuPfXvPav/',
  },
  {
    fileName: '09_SUTTHIDA_SUTTHAJIT.png',
    link: 'https://www.facebook.com/jenjenny.jeje/posts/4693690577404575',
  },
  {
    fileName: '10_TUNYONG_MAHAWAN.png',
    link: 'https://www.facebook.com/FB.Tunyong/posts/10229188724329063',
  },
  {
    fileName: '11_MEYOUUUUUUUUUUUUUUUUUU.png',
    link: 'https://www.instagram.com/p/CYWV525v7Tw/',
  },
  {
    fileName: '12_PIYANART_RODPAEWPARN.png',
    link: 'https://www.facebook.com/Featurings.Somo/posts/4806761486013736',
  },
  {
    fileName: '13_NIDCHANAN_PAKDEEWUT.png',
    link: 'https://www.facebook.com/yammie.nidcha/posts/3150202641921100',
  },
  {
    fileName: '15_IN.CHA.png',
    link: 'https://www.instagram.com/p/CYVhW1Pryxg/',
  },
  {
    fileName: '16_RRENNAM.png',
    link: 'https://www.instagram.com/p/CYVgSk6l9_G/',
  },
  {
    fileName: '17_SUVICHAK_PETCHAMNI.png',
    link:
      'https://www.facebook.com/permalink.php?story_fbid=2808799446086397&id=100008691071451',
  },
  {
    fileName: '18_KAWPODD.png',
    link: 'https://www.instagram.com/p/CYVdyLcvs9n/',
  },
  {
    fileName: '19_TUKTANONTIYA.png',
    link: 'https://www.instagram.com/p/CYVazR5ppy0/',
  },
  {
    fileName: '20_MART.MACHEEZE.png',
    link: 'https://www.instagram.com/p/CYBcNxDllfn/',
  },
  {
    fileName: '21_AUMMIIAYMN.png',
    link: 'https://www.instagram.com/p/CYBlE1olXjC/',
  },
  {
    fileName: '22_MEAYINGZ.png',
    link: 'https://www.instagram.com/p/CX_K366p2RC/',
  },
  {
    fileName: '23_CHERRY_BEAR.png',
    link: 'https://www.facebook.com/piicherrybear/posts/5241081845905366',
  },
  {
    fileName: '24_ปริญพัชญ์_ปัญจศรัญศิลป์.png',
    link: 'https://www.facebook.com/alljanee/posts/4982624598525593',
  }
];

import React, { useEffect, CSSProperties, useState, useRef, Fragment } from 'react';
import { images, spacing, color, palette } from '../../Layouts/theme';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_EN,
  HEADER_TH,
  BODY_1_EN,
  BODY_1_TH,
  BODY_2_EN,
  BODY_2_TH,
  NON_PADDING_LEFT_RIGHT,
  ImageHorizontal,
  CONTENT_CARD,
  BUTTON_STYLE,
  CONTENT_CENTER,
  ImageMobileBox,
  BODY_3_TH,
  BODY_3_EN,
  BODY_7_TH,
  BODY_7_EN,
  HEADER_2_TH,
  HEADER_2_EN,
} from '../../Layouts/styled/main';
import { Container, Row, Col } from 'react-bootstrap';
import { articles } from '../../db/articles';
import { products } from '../../db/products';
import { useTranslation } from 'react-i18next';
import { graphql, Link, navigate } from 'gatsby';
import i18next from 'i18next';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Img from 'gatsby-image';
import { getSourceImage, getSourceImageMultiSize } from '../../utils/image-sharp';
import { CardCaption } from '@/components/card-caption/card-caption';
import LinkTo from '../../components/link/link';
import { customerReview } from '../../db/reviews';
import { useMst } from '@/models/root-store';

const ColBox = styled(Col)`
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media (max-width: 576px) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
`;

const BREADCRUMB: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const LINE_DEFAULT: CSSProperties = {
  borderTop: `1.5px solid ${color.mainColor4}`,
};

const PRODUCT_DESCRIPTION: CSSProperties = {
  paddingTop: spacing[3],
};

const BOTTOM_DESCRIPTION: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-end',
};

const ARROW: CSSProperties = {
  color: color.mainColor4,
  position: 'absolute',
  bottom: 0,
  right: 15,
  zIndex: 999,
};

const LINK_TO: CSSProperties = {
  textDecoration: 'none',
  color: color.textBlack,
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
};

const RELATED_PRODUCT: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const RELATED_PRODUCT_LIST: CSSProperties = {
  marginBottom: 30,
};

const replaceHtml = (html: string) => {
  let htmlResult = '';
  html.split(/src="{([^}]+)}"/).forEach(str => {
    if (str.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)) {
      try {
        htmlResult += `src="${require(`../../images/${str.trim()}`).default}" class="article-image-full-size"`;
      } catch (err) {
        htmlResult += `src=""`;
      }
    } else {
      htmlResult += str;
    }
  });

  htmlResult = htmlResult
    .replaceAll(/<h[1]>/ig, `<h1 class="topic-h2-${i18next.language.toLowerCase()}">`)
    .replaceAll(/<h[2]>/ig, `<h2 class="topic-h3-${i18next.language.toLowerCase()}">`)
    .replaceAll(/<h[3]>/ig, `<h3 class="topic-h4-${i18next.language.toLowerCase()}">`)
    .replaceAll(/<h[4]>/ig, `<h4 class="topic-h5-${i18next.language.toLowerCase()}">`)
    .replaceAll(/<h[5]>/ig, `<h5 class="topic-h6-${i18next.language.toLowerCase()}">`)
    .replaceAll(/<h[6]>/ig, `<h6 class="topic-h6-${i18next.language.toLowerCase()}">`)
  return htmlResult;
}

const ArticleId = ({ articleId, data }) => {
  const { t } = useTranslation();
  const { ouayUnStore } = useMst();
  const [article, setArticle] = useState<any>(null);
  const [arrRelated, setArrRelated] = useState<Array<string>>(null);
  const [relatedProducts, setRelatedProducts] = useState<Array<any>>(null);
  const customerReviewRef = useRef<any>(null);

  const executeScroll = () => customerReviewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  useEffect(() => {
    const article = articles.filter(
      (article: any) => article.article_id === articleId,
    )[0];

    setArticle(article);
    const relatedProducts = [
      article['related_product_code_1'],
      article['related_product_code_2'],
      article['related_product_code_3'],
    ].filter(Boolean);

    setArrRelated(relatedProducts);
  }, []);

  useEffect(() => {
    if (arrRelated?.length) {
      const relatedProducts = products.filter((product: any) =>
        arrRelated.includes(product.product_code),
      );
      setRelatedProducts(relatedProducts);
    }
  }, [arrRelated?.length && JSON.stringify(arrRelated)]);

  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    textDecorationLine: 'none',
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  const imageName = article?.full_screen_image_path ?? '';

  return (
    <main className={'main'}>
      {/* //? section 1 */}
      <section className="is-not-hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            <ColBox xs="12">
              <ImageMobileBox data-aos="fade-up">
                {/* <ImageHorizontal
                  src={
                    !article?.full_screen_image_path
                      ? images.defaultBody
                      : require(`../../images/articles/${article.full_screen_image_path}`)
                          .default
                  }
                  alt={`article-image-${articleId}`}
                /> */}
                <Img
                  fluid={getSourceImageMultiSize(
                    imageName,
                    data,
                    '(min-width: 768px)',
                  )}
                  alt={'ouay-article-lg-image' + imageName}
                  style={{
                    position: undefined,
                  }}
                  imgStyle={{
                    borderRadius: '0px 80px',
                  }}
                  className={'topic-image'}
                />
              </ImageMobileBox>
            </ColBox>
          </Row>
        </Container>
      </section>

      <section className="hd-size">
        <Container fluid>
          <Row
            className="justify-content-center"
            style={{ height: '50%', backgroundColor: color.backgroundWhite }}
          >
            <Col style={CONTENT_CENTER}>
              <div data-aos="fade-up" style={{ width: 1050, height: 480 }}>
                {/* <ImageHorizontal
                  src={
                    !article?.full_screen_image_path
                      ? images.defaultBody
                      : require(`../../images/articles/${article.full_screen_image_path}`)
                        .default
                  }
                  alt={`article-image-${articleId}`}
                /> */}
                <Img
                  fluid={getSourceImageMultiSize(
                    imageName,
                    data,
                    '(min-width: 768px)',
                  )}
                  alt={'ouay-article-lg-image' + imageName}
                  style={{
                    position: undefined,
                  }}
                  imgStyle={{
                    borderRadius: '0px 110px',
                  }}
                  className={'topic-image'}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* //? breadcrumbs */}
      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block" style={{ paddingTop: spacing[6] }}>
        <Container fluid>
          <Row className="justify-content-center" data-aos="fade-up">
            <Col xs="10" style={NON_PADDING_LEFT_RIGHT}>
              <div style={BREADCRUMB}>
                <BreadcrumbMain
                  style={{
                    paddingRight: spacing[2],
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                  }}
                  onClick={() => navigate('/article')}
                >{`${t('article')}`}</BreadcrumbMain>
                <div
                  style={{
                    borderLeft: '1px solid black',
                    paddingLeft: spacing[2],
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                    color: color.mainColor3,
                  }}
                >
                  {article
                    ? article[`article_name_${i18next.language}`]
                    : ''}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* //? for screen size md++ */}
      <section className="d-none d-md-block" style={{ paddingTop: spacing[7] }}>
        <Container fluid>
          <Row className="justify-content-center" data-aos="fade-up">
            <Col xs="2" style={{ maxWidth: 180 }} />
            <Col xs="10" style={{ maxWidth: 1080 }}>
              <div style={BREADCRUMB}>
                <BreadcrumbMain
                  style={{
                    paddingRight: spacing[2],
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                  }}
                  onClick={() => navigate('/article')}
                >{`${t('article')}`}</BreadcrumbMain>
                <div
                  style={{
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                    borderLeft: '1px solid black',
                    paddingLeft: spacing[2],
                    color: color.mainColor3,
                  }}
                >
                  {article
                    ? article[`article_name_${i18next.language}`]
                    : ''}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container fluid style={{ paddingTop: 60 }}>
        <Row className="justify-content-center">
          <div data-aos="fade-up">
            <CustomerReviewBtn onClick={executeScroll}>
              <CustomerReviewLabel>{t('customerReview')}</CustomerReviewLabel>
            </CustomerReviewBtn>
          </div>
        </Row>
      </Container>

      {/* //? section 2 */}
      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block">
        <Container fluid style={{ paddingTop: 60, paddingBottom: 50 }}>
          <Row className="justify-content-center">
            <Col xs="10" sm="8" style={{ ...CONTENT_CENTER, maxWidth: 650 }} className="text-spacing-l-r">
              <div data-aos="fade-up">
                <div
                  style={{
                    ...(i18next?.language === 'th'
                      ? MOBILE_HEADER_TH
                      : MOBILE_HEADER_EN),
                    textAlign: 'center',
                  }}
                >
                  {t('about_ouay_un_title')}
                </div>
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: article
                      ? replaceHtml(i18next.language === 'th' ? article[`full_content_mobile_${i18next.language}`] : article[`full_content_${i18next.language}`])
                      : '',
                  }}
                  style={{
                    ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
                    textAlign: 'center',
                  }}
                />
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Row className="justify-content-center">
            <Col xs="auto">
              <button style={BUTTON} onClick={() => navigate(`/article`)}>
                {t('back')}
              </button>
            </Col>
          </Row>
        </Container>
      </section>
      {/* //? for screen size md++ */}
      <section className="d-none d-md-block">
        <Container fluid style={{ paddingBottom: 40, paddingTop: 40 }}>
          <Row className="justify-content-center">
            <Col md="7" style={{ ...CONTENT_CENTER, maxWidth: 650 }}>
              <div data-aos="fade-up">
                <div
                  dangerouslySetInnerHTML={{
                    __html: article
                      ? article[`article_name_${i18next.language}`]
                      : '',
                  }}
                  style={{
                    ...(i18next?.language === 'th' ? HEADER_TH : HEADER_EN),
                    textAlign: 'center',
                  }}
                />
                <br />
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceHtml(article
                      ? article[`full_content_${i18next.language}`]
                      : ''),
                  }}
                  style={{
                    ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
                    textAlign: 'center',
                  }}
                />
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Row className="justify-content-center">
            <Col xs="auto">
              <button style={BUTTON} onClick={() => navigate(`/article`)}>
                {t('back')}
              </button>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container fluid style={{ maxWidth: 1080 }}>
          <Row>
            <Col xl={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
              <hr style={LINE_DEFAULT} />
            </Col>
          </Row>
          <Row className="justify-content-center" data-aos="fade-up" style={{ paddingTop: 30, paddingBottom: 50 }}>
            {article?.shop_url && article?.shop_detail ? (<>
              <Col
                md={{ span: 3, offset: 3 }}
                sm={{ span: 4, offset: 1 }}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <LinkTo
                  to={article.shop_url}
                  style={{
                    ...BUTTON,
                    padding: '10px 30px',
                    borderRadius: 30
                  }}
                  target={'_blank'}
                  children={
                    <span style={{ fontSize: 17 }}>{'Shop online'}</span>
                  }
                />
              </Col>
              <Col
                md={{ span: 6, offset: 0 }}
                sm={{ span: 7, offset: 0 }}
                xs={12}
              >
                <div
                  className="shop-detail"
                  style={{ lineHeight: 1.5 }}
                  dangerouslySetInnerHTML={{
                    __html: article.shop_detail
                  }}
                />
              </Col>
            </>)
              : (<>
                <Col xl={{ span: 2, offset: 0 }} md={{ span: 2, offset: 1 }} xs={{ span: 11, offset: 1 }}>
                  <div
                    style={{
                      fontWeight: 'bold',
                      paddingBottom: 30,
                      ...(i18next.language === 'th' ? BODY_3_TH : BODY_3_EN),
                    }}
                  >
                    {t('relateProduct')}
                  </div>
                </Col>
                <Col xs="12" xl={10} md={8} style={RELATED_PRODUCT}>
                  {relatedProducts?.map((product: any, index: number) => {
                    return (
                      <Col key={index} md={4} xs={6} style={RELATED_PRODUCT_LIST}>
                        <Link
                          to={`/product/${product.product_code}`}
                          style={LINK_TO}
                          state={{
                            productType: '',
                          }}
                        >
                          <CardCaption
                            source={getSourceImage(
                              product?.product_image_path_1
                                ? product.product_image_path_1
                                : 'no-img.png',
                              data.products.edges,
                            )}
                            containerStyle={{
                              borderRadius: '60px 0px',
                              background: product?.theme_code
                                ? `linear-gradient(180deg, ${palette.productSet1[`${product.theme_code}1`]
                                } 60%, ${palette.productSet1[`${product.theme_code}2`]
                                } 40%)`
                                : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                            }}
                            disableAos
                            topic={product[`product_name_${i18next.language}`]}
                            content={product[`details_paragraph_1_${i18next.language}`]}
                            topicStyle={{
                              ...(i18next.language === 'th'
                                ? BODY_3_TH
                                : BODY_3_EN),
                              marginBottom: spacing[1],
                            }}
                            contentStyle={{
                              flex: 1,
                              paddingBottom: 5,
                              ...(i18next.language === 'th'
                                ? BODY_7_TH
                                : BODY_7_EN),
                            }}
                          />
                        </Link>
                      </Col>
                    );
                  })}
                </Col>
              </>)}
          </Row>

          {articleId === '18' ? (
            <Fragment key="customer-review-fragment">
              <div
                ref={customerReviewRef}
                style={{
                  scrollMarginTop: ouayUnStore?.screenSize?.width < 768 ? 100 : 250,
                }}
              ></div>

              <Row
                className="justify-content-center"
                data-aos="fade-up"
                style={{
                  paddingBottom: 30,
                  ...(article?.shop_url && article?.shop_detail ? { marginTop: 20 } : { marginTop: -10 })
                }}
              >
                <span
                  style={{
                    ...(i18next.language === 'th'
                      ? HEADER_2_TH
                      : HEADER_2_EN),
                    fontSize: 30
                  }}
                >{t('customerReview')}</span>
              </Row>
              <Row data-aos="fade-up" style={{ paddingBottom: 50 }}>
                <Col
                  xl={{ span: 12, offset: 0 }}
                  md={{ offset: 1, span: 10 }}
                  xs={{ offset: 1, span: 10 }}
                >
                  <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                    {data.reviews?.edges?.length && customerReview?.map((review, index: number) => (<Col
                      key={`customer-review-${index}`}
                      md={3}
                      sm={4}
                      xs={6}
                      style={{ marginBottom: 15, paddingBottom: 15, paddingTop: 15 }}
                    >
                      <LinkTo
                        to={review.link}
                        target={'_blank'}
                        children={
                          <Img
                            fluid={getSourceImage(review.fileName, data.reviews.edges, '(max-width: 768px)')}
                            alt={`review_${review.fileName}`}
                            style={{
                              position: undefined
                            }}
                          />
                        }
                      />
                    </Col>))}
                  </Row>
                </Col>
              </Row>
            </Fragment>
          ) : null}
        </Container>
      </section>
    </main>
  );
};
export default ArticleId;

const BreadcrumbMain = styled.span`
  cursor: pointer;
`

const CustomerReviewBtn = styled.button`
  background-color: ${color.red};
  border: 1px solid ${color.darkRed};
  color: ${color.backgroundWhite};
  padding: 5px 20px;
  border-radius: 30px;
`

const CustomerReviewLabel = styled.span`
  font-size: 22px;
  font-weight: bold;
`

export const query = graphql`
  query {
    mobileImage: allFile(
      filter: {
        relativePath: { regex: "/articles/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 630, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    desktopImage: allFile(
      filter: {
        relativePath: { regex: "/articles/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1150, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    products: allFile(filter: { relativePath: { regex: "/product/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 335, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    reviews: allFile(filter: { relativePath: { regex: "/reviews/" }, extension: { eq: "png" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 335, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
